import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import prixContent from '../config/contents/prix';
import Image from "../components/image";
import GradientButton from "../components/gradient-button";

import { signup } from '../assets/data/links.json';
import '../assets/css/prix.css';

const PrixPage: React.FC<PageProps<any>> = ({ data }) => {
  let _isMounted = true;
  const { heroBg, site } = data;
  const { title, path, description } = site?.siteMetadata?.pages?.prix;
  const [pack, setPack] = useState<'us' | 'dz' | 'eu'>('us');

  useEffect(() => {
    const getUserLocation = async () => {
      try {
        const response = await fetch(`https://beta.dropzik.com/wp-json/dacontact/v1/my-location`);
        const data = await response.json();

        if (data?.is_eu === 1) {
          _isMounted && setPack('eu');
        } else if (data?.country_code === 'DZ') {
          _isMounted && setPack('dz');
        } else {
          _isMounted && setPack('us');
        }
      } catch (error) { }
    }
    getUserLocation();
    return () => {
      _isMounted = false
    }
  }, []);

  return (
    <Layout>
      <SEO title={title} description={description} path={path} image={heroBg?.childImageSharp.fluid.src} />

      <div className="d-block prix__page">

        <section className="d-block position-relative blend-mode_lighten">

          <Image
            className='image__full-width h-100 w-100 position-absolute'
            src={heroBg?.childImageSharp?.fluid}
          />

          <div className="container position-relative">
            <div className="container__row row">
              <div className='hero__content d-flex flex-column justify-content-center text-white pt-4'>

                <h1 className="title m-0 mb-3 pt-5">
                  UN <strong className="d-bold">SERVICE D’EXCELLENCE</strong>, DES <strong className="d-bold">PRIX AVANTAGEUX</strong>
                </h1>
                <p className='m-0'>
                  {prixContent.section1.description.part1}
                  {` ${prixContent.section2.cards[pack][1].price} ${prixContent.section2.cards[pack][1].currency}`}
                  {prixContent.section1.description.part2}
                </p>

                <div className='hero__action mt-4 mt-md-5'>
                  <GradientButton to={signup} className='d-bold px-5' external>
                    {prixContent.section1.linkLabel}
                  </GradientButton>
                </div>

              </div>
            </div>
          </div>

        </section>

        <section className="bg-sliver pb-3 pb-md-5">
          <div className="container pb-0 pb-md-5">
            <div className="caontainer__row row flex-column pb-0 pb-md-5 mb-0 mb-md-4">

              <h2 className="title my-3 my-md-5 pt-4 text-center">
                Nos <strong className="d-bold">3 Packs</strong>
              </h2>

              <div className="d-flex flex-column flex-md-row">
                {
                  prixContent.section2.cards[pack].map(item => (
                    <div className="card flex_1 bg-darkengrey border-0 rounded-0 text-white text-center" key={item.title}>

                      <div className="card__header">
                        <h2 className='card-title card__title font-weight-light d-flex flex-column h-auto position-relative overflow-hidden p-2'>
                          <span className="fake-bg"></span>
                          <span className='position-relative'>Pack{` `}</span>
                          <strong className="d-bold position-relative">{item.title}</strong>
                        </h2>
                        <div>
                          <strong className="d-bold">{item.price}</strong>{` `}
                          <span className="font-wight-light fsz-20">{item.currency}/{item.per || 'titre'}</span>
                        </div>
                      </div>

                      <div className="card-body p-0">
                        <div className="card-text">
                          <ul className='list-group-flush p-0'>
                            {
                              item.items.map(li => (<li key={li} className='list-group__item list-group-item bg-transparent p-3 font-weight-light fsz-20'>{li}</li>))
                            }
                          </ul>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center flex-column m-3 mt-4">
                        <GradientButton className={`card__btn d-bold w-100`} to={signup} external>
                          créer un compte gratuitement
                        </GradientButton>
                      </div>

                    </div>
                  ))
                }
              </div>

              <blockquote className='blockquote p-3 mt-3'>{prixContent.section2.note}</blockquote>

            </div>
          </div>
        </section>

      </div>

    </Layout >
  );
}

export const query = graphql`
  query prixQuery {
    site {
      siteMetadata {
        lang
        pages {
          prix {
            title
            path
            description
          }
        }
      }
    }
    heroBg: file(relativePath: { eq: "prix/bg-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default PrixPage;
