const commonItems = [
  `250 plateformes`,
  `Pas de frais annuels / Payez une fois, à vie !`,
  `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
  `ISRCs et code(s)-barres / inclus pour toute commande`,
  `YouTube Content ID`,
  `Assistance par email / Sous 24 heures ouvrées`,
];

const prixContent = {
  section1: {
    title: `Excellent service, prix avantageux`,
    description: {
      part1: `La distribution numérique devrait être accessible à tous les artistes, c’est pour quoi nos prix commencent seulement à partir de`,
      part2: `. Nous ferons en sorte que votre musique soit diffusée et disponible à l’achat sur internet. Votre musique sera accessible à tous.`,
    },
    linkLabel: `Créez un compte gratuitement`,
  },
  section2: {
    mainTitle: `Nos 3 Packs`,
    description: `Voici les packs que  nous offrons`,
    linkLabel: `Créez un compte gratuitement`,
    cards: {
      us: [
        {
          title: `Star`,
          price: 5,
          currency: 'USD',
          items: [
            `250 plateformes`,
            `20% de commission`,
            `Pas de frais annuels / Payez une fois, à vie !`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO`
          ]
        },
        {
          title: `Super Star`,
          price: 10,
          currency: 'USD',
          items: [
            `250 plateformes`,
            `0% de commission`,
            `Pas de frais annuels / Payez une fois, à vie !`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO`
          ]
        },
        {
          title: `Label`,
          price: 99,
          per: 'Année',
          currency: 'USD',
          items: [
            `Distribution illimité`,
            `250 plateformes`,
            `0% de commission`,
            `Frais annuels`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `Assistance téléphonique / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO`
          ],
        }
      ],
      dz: [
        {
          title: `Star`,
          price: 1000,
          currency: 'DZD',
          items: [
            `250 plateformes`,
            `20% de commission`,
            `Pas de frais annuels / Payez une fois, à vie !`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO`
          ]
        },
        {
          title: `Super Star`,
          price: 2000,
          currency: 'DZD',
          items: [
            `250 plateformes`,
            `0% de commission`,
            `Pas de frais annuels / Payez une fois, à vie !`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO`
          ]
        },
        {
          title: `Label`,
          price: 14000,
          per: 'Année',
          currency: 'DZD',
          items: [
            `Distribution illimité`,
            `250 plateformes`,
            `0% de commission`,
            `Frais annuels`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `Assistance téléphonique / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO`
          ],
        }
      ],
      eu: [
        {
          title: `Star`,
          price: 4,
          currency: 'EUR',
          items: [
            `250 plateformes`,
            `20% de commission`,
            `Pas de frais annuels / Payez une fois, à vie !`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO`
          ]
        },
        {
          title: `Super Star`,
          price: 8,
          currency: 'EUR',
          items: [
            `250 plateformes`,
            `0% de commission`,
            `Pas de frais annuels / Payez une fois, à vie !`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO`
          ]
        },
        {
          title: `Label`,
          price: 99,
          per: 'Année',
          currency: 'EUR',
          items: [
            `Distribution illimité`,
            `250 plateformes`,
            `0% de commission`,
            `Frais annuels`,
            `Pas de minimum de versement / Vous pouvez retirer la totalité de votre argent`,
            `ISRCs et code(s)-barres / inclus pour toute commande`,
            `Assistance par email / Sous 24 heures ouvrées`,
            `Assistance téléphonique / Sous 24 heures ouvrées`,
            `YouTube Content ID`,
            `Enregistrement de label premium`,
            `Fenan PRO*`
          ],
        }
      ],
    },
    note: `Si votre forfait Label expire et que vous ne souhaitez pas le renouveler, vos sorties passent au forfait Gratuit. Vous ne payez aucun frais supplémentaire. Votre musique reste en ligne, vous percevez toujours vos revenus et pouvez les percevoir à tout.`
  }
};

export default prixContent;